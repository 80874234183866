



















































































import { Component, Vue } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';


more(Highcharts);


const useHtmlElement = (possibleEl: any, block: (el: HTMLElement) => void) => {
    if (possibleEl instanceof HTMLElement) {
        block(possibleEl);
    }
};

const combineHighchartOptions = (...sources: Highcharts.Options[]) => {
    const result: Highcharts.Options = {};

    sources.forEach(source => {
        Object.getOwnPropertyNames(source).forEach(key => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            result[key] = source[key];
        });
    });

    return result;
};


const renderHighcharts = (possibleEl: any, options: Highcharts.Options) => {
    useHtmlElement(possibleEl, el => Highcharts.chart(el, options));
};

const renderHighchartsWaterfall = (possibleEl: any, options: Highcharts.Options) => {
    renderHighcharts(possibleEl, combineHighchartOptions(
        {
            chart: {
                type: 'waterfall',
                plotBorderWidth: 0,
                plotBackgroundColor: undefined
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            plotOptions: {
                waterfall: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: '#0E304C',
                            fontSize: '2em',
                            textOutline: "0.5px",
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormatter: function (this: Highcharts.Point): string {
                    const y = this.y;
                    if (typeof y !== 'number') {
                        return '';
                    }

                    return `<b style="font-size: 1.5em;">${Highcharts.numberFormat(y, 2, ',')}</b>`;
                }
            }
        },
        options
    ));
};

const renderHighchartsDonut = (possibleEl: any, options: Highcharts.Options) => {
    renderHighcharts(possibleEl, combineHighchartOptions(
        {
            chart: {
                plotBackgroundColor: undefined,
                plotBorderWidth: 0,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: '',
                align: 'center',
                verticalAlign: 'middle',
                y: 60
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<b style="font-size: 1.5em;">{point.y:.2f}%</b>'
            },
            exporting: {
                enabled: false
            }
        },
        options
    ));
};

const renderHighchartsColumn = (possibleEl: any, options: Highcharts.Options) => {
    renderHighcharts(possibleEl, combineHighchartOptions(
        {
            chart: {
                type: 'column',
                plotBorderWidth: 0,
                plotBackgroundColor: undefined,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                categories: [
                    ''
                ],
                crosshair: true
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            // color: 'white',
                            color: '#0E304C',
                            fontSize: '2em',
                            textOutline: "0.5px",
                            // textShadow: '0 0px 8px black'
                        }
                    }
                }
            },
            tooltip: {
                pointFormat: '<b style="font-size: 1.5em;">{point.y:.1f}</b>'
            }
        },
        options
    ));
};


const renderDonutAndColumn = (value: number, max: number, possibleDonutEl: any, possibleColumnEl: any) => {
    const floatValue = value / max;
    const percent = Math.round(floatValue * 100);
    const angle = 360 * floatValue;

    const dataNameValue = String(value);
    const dataNamePercent = `${percent}%`;

    renderHighchartsDonut(possibleDonutEl, {
        title: {
            text: 'Процент'
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -25,
                    style: {
                        fontWeight: 'bold',
                        color: '#0E304C',
                        fontSize: '2em',
                        textOutline: "0.5px",
                        // textShadow: '0 0px 8px black'
                    }
                },
                startAngle: 0,
                endAngle: angle
            }
        },
        series: [{
            type: 'pie',
            name: '',
            innerSize: '50%',
            data: [
                {
                    name: dataNamePercent,
                    y: percent
                }
            ]
        }]
    });

    renderHighchartsColumn(possibleColumnEl, {
        chart: {
            type: 'column',
            plotBorderWidth: 0,
            plotBackgroundColor: undefined
        },
        title: {
            text: `Балл (макс. - ${max})`
        },
        yAxis: {
            min: 0,
            max: max,
            title: {
                text: ''
            },
            maxRange: max
        },
        series: [
            {
                type: 'column',
                name: dataNameValue,
                data: [value]
            }
        ]
    });
};


@Component
export default class EfficiencyMark extends Vue {
    private mounted() {
        this.renderEfficiencyMarkChart();
        this.renderD();
        this.renderZ();
        this.renderG();
        this.renderL();
        this.renderW();
    }

    private renderEfficiencyMarkChart() {
        renderHighchartsWaterfall(this.$refs.efficiencyMarkChart, {
            series: [{
                type: 'waterfall',
                data: [
                    {
                        name: 'D',
                        y: 49
                    },
                    {
                        name: 'Z',
                        y: 13
                    },
                    {
                        name: 'G',
                        y: 12
                    },
                    {
                        name: 'L',
                        y: 5
                    },
                    {
                        name: 'W',
                        y: 0.4
                    },
                    {
                        name: 'Всего',
                        isSum: true
                    }
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.y, 2, ',');
                    },
                    style: {
                        fontWeight: 'bold'
                    }
                },
                pointPadding: 0
            }]
        });
    }

    private renderD() {
        renderDonutAndColumn(43, 50, this.$refs.dDonut, this.$refs.dCol);
    }

    private renderZ() {
        renderDonutAndColumn(13, 20, this.$refs.zDonut, this.$refs.zCol);
    }

    private renderG() {
        renderDonutAndColumn(12, 20, this.$refs.gDonut, this.$refs.gCol);
    }

    private renderL() {
        renderDonutAndColumn(5, 10, this.$refs.lDonut, this.$refs.lCol);
    }

    private renderW() {
        renderDonutAndColumn(0.4, 6.5, this.$refs.wDonut, this.$refs.wCol);
    }
}
