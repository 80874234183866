






























import {Component, Vue} from 'vue-property-decorator';
import Decomposition from './Decomposition.vue'
import Prognosis from './Prognosis.vue'
import ExecPrognosis from './ExecPrognosis.vue'
import component from "vue-focus-lock";
import Multiselect from "vue-multiselect";
import EfficiencyMark from './EfficiencyMark.vue';

type ButtonVariant = 'primary' | '';


@Component({
        components:
            {
                Decomposition: Decomposition,
                Prognosis: Prognosis,
                ExecPrognosis: ExecPrognosis,
                EfficiencyMark: EfficiencyMark
            }
    }
)
export default class Index extends Vue {
    // #region Для "Декомпозиция ЦИ"
    private get decompositionActive(): boolean {
        return (this.$route.path === '/demo-budget-execution/sgp/target-indicators/decomposition');
    }

    private get decompositionButtonVariant(): ButtonVariant {
        if (this.decompositionActive) {
            return 'primary';
        }
        return '';
    }

    private onDecompositionClick() {
        this.$router.push({path: '/demo-budget-execution/sgp/target-indicators/decomposition'});
    }

    // #endregion

    // #region Для "Прогноз ЦИ ПРО"
    private get prognosisActive(): boolean {
        return (this.$route.path === '/demo-budget-execution/sgp/target-indicators/prognosis');
    }

    private get prognosisButtonVariant(): ButtonVariant {
        if (this.prognosisActive) {
            return 'primary';
        }
        return '';
    }

    private onPrognosisClick() {
        this.$router.push({path: '/demo-budget-execution/sgp/target-indicators/prognosis'});
    }

    // #endregion
}
