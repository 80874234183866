

















































































































import { Component, Vue } from 'vue-property-decorator';
import * as Types from '../types';
import data from '../data';


interface IDataRow {
    id: number;
    sgpPrt: Types.ISgpPrt;
    target: Types.ITarget;
    rowspan?: number;
    indicator: string;
    values: {
        y2020?: number;
        y2021p?: number;
        y2021f?: number;
        diff?: number;
        y2022p?: number;
        y2023p?: number;
        y2024p?: number;
    };
    path?: string;
}


const getFromMap = <T extends any>(map: Map<any, T>, id: number): T => {
    const result = map.get(id);
    if (result === undefined) {
        const message = `Cannot find element with id #${id}`;
        console.error(message, map);
        throw new Error(message);
    }
    return result;
};


const initialSelectedTargets: Types.ITarget[] = [];
const targetSelectOptions: object[] = [];
data.targets.forEach((target) => {
    initialSelectedTargets.push(target);
    targetSelectOptions.push({
        text: target.title,
        value: target
    });
});


const initialSelectedSgpPrts: Types.ISgpPrt[] = [];
const sgpPrtSelectOptions: object[] = [];
data.sgpPrts.forEach((sgpPrt) => {
    initialSelectedSgpPrts.push(sgpPrt);
    sgpPrtSelectOptions.push({
        text: sgpPrt.title,
        value: sgpPrt
    });
});


// впи**у, данные будут здесь
const dataRows: IDataRow[] = [];
(() => {
    const sgpPrtMap = data.sgpPrtMap;
    const targetMap = data.targetMap;

    // 0 - Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %
    dataRows.push({
        id: 0,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 0),
        rowspan: 6,
        indicator: 'Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %',
        values: {
            y2020: 87.2,
            y2021p: 89,
            y2021f: 89.2,
            diff: 0.2,
            y2023p: 91,
            y2024p: 93
        },
        path: '/demo-budget-execution/roads/roads-map'
    });

    // 1 - Общая протяженность автодорог местного значения, км
    dataRows.push({
        id: 1,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 0),
        indicator: 'Общая протяженность автодорог местного значения, км',
        values: {
            y2020: 3711,
            y2021p: 3711,
            y2021f: 3711,
            y2022p: 3711,
            y2023p: 3711,
            y2024p: 3711,
            diff: 0
        }
    });

    // 2 - В т.ч. находящихся в хорошем и удовлетворительном состоянии, км
    dataRows.push({
        id: 2,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 0),
        indicator: 'В т.ч. находящихся в хорошем и удовлетворительном состоянии, км',
        values: {
            y2020: 3237,
            y2021p: 3303,
            y2021f: 3311,
            // y2022p: 3377,
            y2023p: 3377,
            y2024p: 3451,
            diff: 8
        }
    });

    // 3 - Изменение +/-
    dataRows.push({
        id: 3,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 0),
        indicator: 'Изменение +/-',
        values: {
            y2021p: 74,
            y2021f: 82,
            y2022p: 74,
            y2023p: 74,
            y2024p: 74,
            diff: 8
        }
    });

    // 4 - Протяженность автодорог, которые перейдут в неудовлетворительное состояние (износ), км
    dataRows.push({
        id: 4,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 0),
        indicator: 'Протяженность автодорог, которые перейдут в неудовлетворительное состояние (износ), км',
        values: {
            y2021p: 148,
            y2021f: 148,
            y2022p: 148,
            y2023p: 148,
            y2024p: 148,
            diff: 0
        }
    });

    // 5 - Протяженность автодорог, которые необходимо отремонтировать для достижения планового показателя
    dataRows.push({
        id: 5,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 0),
        indicator: 'Протяженность автодорог, которые необходимо отремонтировать для достижения планового показателя',
        values: {
            y2021p: 222,
            y2021f: 230,
            y2022p: 222,
            y2023p: 222,
            y2024p: 222,
            diff: 8
        }
    });

    // 6 - Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест
    dataRows.push({
        id: 6,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 1),
        rowspan: 4,
        indicator: 'Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест',
        values: {
            y2020: 8164,
            y2021p: 5495,
            y2021f: 5250,
            diff: -245,
            y2022p: 1850,
            y2023p: 1850,
            y2024p: 500,
        },
        path: '/demo-budget-execution/schools/schools-map'
    });

    // 7 - Изменение +/-
    dataRows.push({
        id: 7,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 1),
        indicator: 'Изменение +/-',
        values: {
            y2021p: -3090,
            y2021f: 3335,
            diff: 245,
            y2023p: -3645,
            y2024p: -1350,
        }
    });

    // 8 - Прогноз прироста количества школьников в дефицитных школах, чел.
    dataRows.push({
        id: 8,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 1),
        indicator: 'Прогноз прироста количества школьников в дефицитных школах, чел.',
        values: {
            y2021p: 218,
            y2021f: 218,
            diff: 0,
            y2022p: 206,
            y2023p: 206,
            y2024p: 195,
        }
    });

    // 9 - Количество ученических мест, которые необходимо создать для достижения показателя, единиц
    dataRows.push({
        id: 9,
        sgpPrt: getFromMap(sgpPrtMap, 2),
        target: getFromMap(targetMap, 1),
        indicator: 'Количество ученических мест, которые необходимо создать для достижения показателя, единиц',
        values: {
            y2021p: 3308,
            y2021f: 3553,
            diff: 245,
            y2022p: 3851,
            y2023p: 3851,
            y2024p: 1545,
        }
    });
})();


@Component
export default class Prognosis extends Vue {
    // #region Фильтр "Цели"
    private targets: Types.ITarget[] = data.targets;

    private selectedTargets: Types.ITarget[] = initialSelectedTargets;

    private targetSelectOptions: object[] = targetSelectOptions;
    // #endregion


    // #region Фильтр "Источник ЦИ (СГП)"
    private sgpPrts: Types.ISgpPrt[] = data.sgpPrts;

    private selectedSgpPrts: Types.ISgpPrt[] = initialSelectedSgpPrts;

    private sgpPrtSelectOptions: object[] = sgpPrtSelectOptions;
    // #endregion


    // #region Данные
    private get dataRows(): IDataRow[] {
        const result: IDataRow[] = [];

        dataRows.forEach((row) => {
            if (this.selectedTargets.includes(row.target) && this.selectedSgpPrts.includes(row.sgpPrt)) {
                result.push(row);
            }
        });

        return result;
    }
    // #endregion
}
